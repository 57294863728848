// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getTeacherNameReverse,
  getUserDetails,
  handleTokenError,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  isFirstSectionOpen: boolean;
  isSecondSectionOpen: boolean;
  selectedState: string,
  selectedDistrict: string,
  isnumber:number,
  selectedSchool:string,
  showSchoolDropdown: boolean,
  isSchoolConfirmed:boolean,
  // Customizable Area Esnd
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class InviteTeacherController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitApiCallId: any;
  addAdditionalDetailApiCallId: any;
  updateUserStatus: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      selectedState: "",
      selectedDistrict: "",
      isnumber:1,
      isFirstSectionOpen: true,
      isSecondSectionOpen: false,
      selectedSchool:"",
      showSchoolDropdown: true,
      isSchoolConfirmed: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    if (!prevState.selectedState || !prevState.selectedDistrict) {
      if (this.state.selectedState && this.state.selectedDistrict) {
        setTimeout(() => {
          this.setState({
            isFirstSectionOpen: false,
            isSecondSectionOpen: true,
            isnumber: 2
          });
        }, 500);
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson && !responseJson.errors) 
        {
        }
    }
    // Customizable Area End
  }

    handleNavigation = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TeacherSelf");
      runEngine.sendMessage(message.id, message);
    }
    isFirstSectionComplete = () => {
      return this.state.selectedState !== "" && this.state.selectedDistrict !== "";
    };
    isSecondSectionComplete = () => {
      return this.state.selectedSchool !== "";
    };
    toggleSection = (section: 'first' | 'second') => {
      if (section === 'first') {
        this.setState({ isFirstSectionOpen: !this.state.isFirstSectionOpen });
        this.setState({ isnumber: 1 });
      } else {
        if (this.isFirstSectionComplete()) {
          this.setState({ isSecondSectionOpen: !this.state.isSecondSectionOpen });
          this.setState({ isnumber: 2 });
        }
      }
    };
    handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ selectedState: event.target.value as string });
    };
  
    handleDistrictChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ selectedDistrict: event.target.value as string });
    };
    isBothSectionsComplete = () => {
      return this.isFirstSectionComplete() && this.state.isSchoolConfirmed;
    };
  
    handleSchoolChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ 
        selectedSchool: event.target.value as string,
        isSchoolConfirmed: false 
      });
    };
    handleConfirmSchool = () => {
      if (this.isSecondSectionComplete()) {
        this.setState({ isSchoolConfirmed: true });
        console.log("School confirmed:", this.state.selectedSchool);
      }
    };
}

// Customizable Area End

// Customizable Area End
