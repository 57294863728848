// Customizable Area Start
import React from "react";
import { Grid, Button,Box ,Typography} from "@material-ui/core";
export const configJSON = require("./config");
import TeacherSelfOnboardingStep3Controller, {
  Props,
} from "./TeacherSelfOnboardingController";
import "../assets/teacher-onboarding.scss";
import TextField from '@material-ui/core/TextField';



export default class TeacherOnboarding extends TeacherSelfOnboardingStep3Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
   
    return (
      <Grid container className="invite-teacher-container" style={{height:"auto"}}>
        <Grid container item sm={6} className="left">
          <div className="content">
            <span className="step">3/3</span>
            <span className="title">Join your</span>
            <span className="title">colleagues!</span>
            <span className="desc">Great news, School Name is on Classbox. To join</span>
            <span className="desc"> your fellow educators on Classbox, submit a </span>
            <span className="desc">request to have your account connected to</span>
            <span className="desc"> School Name.</span>
          </div>
        </Grid>
        <Grid container item sm={6} className="right" style={{marginTop:"100px"}}>
         <div>
         <Box style={{
            width:"480px",
            background: "white",
            padding:"24px 20px 24px 20px",
            borderRadius:"10px",
            marginBottom:"20px",
          }}>
            <div>
              <div className="school" style={{width:"440px",marginBottom:"20px"}}>
                <h3 style={{color:"#344054",fontSize:"30px"}}>Request to join school</h3>
                <Typography  style={{fontSize:"14px",color:"#667085"}}>Send a request to join <span style={{color:"#667085",fontFamily:"Gotham"}}>School Name.</span>Once approved, you'll receive an email that your request was approved, and your account will gain full access to all Classbox has to offer.</Typography>
              </div>
              <div style={{width:"440px",marginBottom:"20px"}}>
                <h3 style={{color:"#344054",fontSize:"16x"}}>Selected School</h3>
                <Typography  style={{fontSize:"16px",borderRadius:"24px",width: "max-content",color:"#00649E",padding:"12px 16px 12px 16px",background:"#E8F6FE"}}>Charter School Name</Typography>
              </div>
              <hr></hr>
              <div>
                <form >
                  <div style={{ display:"flex",gap:"20px",marginBottom:"20px"}}>
                    <div>
                      <Typography style={{fontSize:"16px",marginBottom:"10px",color:"black"}}>My First Name</Typography>
                      <TextField id="outlined-basic"InputProps={{
                          style: {
                            borderRadius: "10px",
                            border:"1px solid black"
                          }
                        }} variant="outlined"/>
                    </div>
                    <div>
                      <Typography style={{fontSize:"16px",marginBottom:"10px",color:"black"}}>My Last Name</Typography>
                      <TextField id="outlined-basic" variant="outlined"InputProps={{
                          style: {
                            borderRadius: "10px",
                            border:"1px solid black"
                          }
                        }}/>
                    </div>
                  </div>
                  <div>
                    <Typography style={{fontSize:"16px",marginBottom:"10px",color:"black"}}>My School Email</Typography>
                    <input style={{border:"1px solid",marginBottom:"10px",borderRadius:"10px",width:"100%",height:"48px"}} placeholder="  Enter school email address"></input>
                    <span style={{fontSize:"12px",color:"#98A2B3"}}>Please use your school email ending in <span style={{color:"#667085"}}>k12.us.edu</span> to proceed.</span>
                  </div>
                  <div>
                    <Button variant="contained" style={{backgroundColor:"#007DC6",color:"white",borderRadius:"10px",marginTop:"20px",width:"100%",padding:"12px 24px 12px 24px"}}>Request to Join</Button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
         </div>
        </Grid>
      </Grid>
    );
  }
}
// Customizable Area End
